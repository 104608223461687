.main-card {
    max-width: 50em;
    display: block;
    padding-top: 20px;
}

.body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5rem;
}

.main-text {
    color: black;
    font-size: 1.3em;
    line-height: 1.6em;
    width: 100%;
    padding-top: 50px;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.header { 
    font-size: 1.6em;
    font-weight: 500;
    margin: 0;
    font-family: 'Times New Roman';
}

.subheader {
    font-family: 'Times New Roman';
    margin: 0.5rem;
}

.section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 0rem;
    padding-bottom: 1.8rem;
}

.link-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
}

a.link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

a.button {
    display: block;
    margin-right: .5rem;
    margin-left: .5rem;
    padding: .1rem .5rem;
    border-radius: .4rem;
    font-size: 1rem;
    cursor: pointer;
}

a:hover {
    cursor: pointer;
}

#twitter {
    background-image: url('../logos/twitter.png');
    width: 32px;
    height: 32px;
    background-size: 32px;
    background-repeat: no-repeat; 
}

#discord {
    background-image: url('../logos/discord.png');
    width: 35px;
    height: 35px;
    background-size: 35px;
    background-repeat: no-repeat; 
}

#email {
    background-image: url('../logos/email.png');
    width: 32px;
    height: 32px;
    background-size: 32px;
    background-repeat: no-repeat; 
}

#linkedin {
    background-image: url('../logos/linkedin.png');
    width: 32px;
    height: 32px;
    background-size: 32px;
    background-repeat: no-repeat; 
}

#bingx {
    background-image: url('../logos/bingxx.png');
    width: 32px;
    height: 32px;
    background-size: 32px;
    background-repeat: no-repeat; 
    margin-top: 3px;
}
