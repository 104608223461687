html {
    width: 100%;
    height: 100vh;
    margin: 0px;
}

body {
    width: 100%;
    height: 100vh;
    margin: 0px;
    background-color: rgb(220, 220, 220);
    display: flex;
    justify-content: center;
}